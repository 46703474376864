




































import { truncate } from '@/helpers'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'
import { ImplementationResource } from '@/models/implementations/ImplementationResource.ts'
import { EntityCollectionResource } from '@/models/implementations/entities/EntityCollectionResource'

@Component({
  mixins: [clickaway],
})
export default class EntityPicker  extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private isOpen: boolean = false

  private get canGoGlobal(): boolean {
    return this.implementation.canPerformAction('can_see_all_entities')
  }

  private get entitiesUserBelongsTo(): EntityCollectionResource[] {
    return this.implementation.entities.filter((entity) => entity.belongs_to)
  }

  private get currentEntity(): EntityCollectionResource | undefined {
    const entity_id = +this.$route.params.entity_id
    return this.implementation.entities.find((entity) => entity.id === entity_id)
  }

  private get currentEntityText(): string {
    return this.currentEntity?.name ?? 'Global view'
  }

  private toggleOpen() {
    if (!this.canGoGlobal) return
    this.isOpen = !this.isOpen
  }

  private close() {
    this.isOpen = false
  }

  private truncatedLabel(label: string): string {
    return truncate(label, 20)
  }

}
